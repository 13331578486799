import React from "react"
import { Provider } from "react-redux"

import createStore from "./src/state/createStore"

// eslint-disable-next-line react/display-name,react/prop-types
const Component = ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  let resizeTimer;
  const store = createStore();
  function onResize(){
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => {
        store.dispatch({
            type: `SCREEN_RESIZE`,
            screenWidth: window.innerWidth
        });
    }, 80);
  };
  if (typeof window !== "undefined") window.addEventListener('resize', onResize);
  return <Provider store={store}>{element}</Provider>
}

export default Component;