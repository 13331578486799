import wrapWithProvider from "./wrap-with-provider"
import scrollToElement from "scroll-to-element"
export const wrapRootElement = wrapWithProvider

export const onRouteUpdate = ({ location }) => {
  let hash = location.hash
  if (hash) {
    scrollToElement(hash, {
      duration: 1,
    })
  }
  if (window && window.freestar && window.freestar.trackPageView) {
    window.freestar.trackPageView()
  }
}
