import { createStore as reduxCreateStore, applyMiddleware } from "redux";
import { save, load } from "redux-localstorage-simple";

const createStoreWithMiddleware
    = applyMiddleware(
        save({ states: ["clipboard","bottomAdClosedUntil"], namespace: 'yt'}) // Saving done here
    )(reduxCreateStore)

const reducer = (state, action) => {
  if (action.type === `TOGGLE_NAV`) {
    return Object.assign({}, state, {
      navOn: !state.navOn
    })
  } else if (action.type === `HIDE_NAV`) {
    return Object.assign({}, state, {
      navOn: window && window.innerWidth <= 600 ? false : state.navOn
    })
  } else if (action.type === `INIT_NAV`) {
    return Object.assign({}, state, {
      navOn: typeof window === 'object' ? window.innerWidth > 600: null,
      screenWidth: typeof window === 'object' ? window.innerWidth : false
    })
  } else if (action.type === `TOGGLE_DOCK`) {
    return Object.assign({}, state, {
      isDocked: action.isDocked
    })
  } else if (action.type === `TOGGLE_CLIPBOARD`) {
    return Object.assign({}, state, {
      clipboardOpen: action.close === true ? false : !state.clipboardOpen
    })
  } else if (action.type === `TOGGLE_CLIPBOARD_HELP`) {
    return Object.assign({}, state, {
      clipboardHelpOpen: !state.clipboardHelpOpen
    })
  } else if (action.type === `CLEAR_CLIPBOARD`) {
    return Object.assign({}, state, {
      clipboard: [],
      clipboardHelpOpen: true,
      lastCopy: false
    })
  } else if (action.type === `COPY`) {
    if (action.copy.ogText) {
      state.clipboard.push({
        date : action.copy.date,
        text : action.copy.ogText,
        transform: 'original'
      });  
    }
    state.clipboard.push({
      date : action.copy.date,
      text : action.copy.text,
      transform: action.copy.transform
    });
    var _c_index = {};
    var _c = [];
    for (var i = 0; i < state.clipboard.length; i++) {
      var index = state.clipboard[i].transform + state.clipboard[i].text;
      if (_c_index[index] === undefined) {
        _c_index[index] = true;
        _c.push(state.clipboard[i]);
      }
    }
    state.clipboard = _c.slice(-30);
    return Object.assign({}, state, {
      clipboard: state.clipboard,
      clipboardHelpOpen: false,
      lastCopy: action.copy
    })
  } else if (action.type === `STOP_LOADING_TEXT`) {
    return { ...state, stopLoadingText: action.value, loadingText: "" }
  } else if (action.type === `SET_TEXTAREA`) {
    return { ...state, textarea: action.value }
  } else if (action.type === `SET_LOADINGTEXT`) {
    return { ...state, loadingText: action.value }
  } else if (action.type === `SCREEN_RESIZE`) {
    let navOn = state.navOn;
    if ((state.screenWidth > 600) !== (action.screenWidth > 600)) {
      navOn = action.screenWidth > 600;
    }
    return { ...state,
      screenWidth: action.screenWidth,
      navOn: navOn
    }
  } else if (action.type === `CLOSE_BOTTOM_AD_UNTIL`) {
    return Object.assign({}, state, {
      bottomAdClosedUntil: new Date().getTime() + (60 * 5 * 1000)
    })
  }
  return state
}

const initialState = {
  navOn: null,
  isDocked: false,
  lastCopy: false,
  clipboard: [],
  clipboardOpen: false,
  clipboardHelpOpen: false,
  textarea: "",
  loadingText: "",
  stopLoadingText: false,
  screenWidth: false,
  bottomAdClosedUntil: false
}

let loadedState = initialState;

if (typeof window !== 'undefined' && window) {
  loadedState = load({
    states: ["clipboard","bottomAdClosedUntil"],
    preloadedState : initialState,
    namespace: 'yt',
    disableWarnings: true
  });
}

if (!loadedState.clipboard.length) {
  // loadedState.clipboardHelpOpen = true;
}

const createStore = () => createStoreWithMiddleware(reducer, loadedState)
export default createStore
