exports.components = {
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/blog_index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/blog_page.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-emoji-category-page-js": () => import("./../../../src/templates/emoji_category_page.js" /* webpackChunkName: "component---src-templates-emoji-category-page-js" */),
  "component---src-templates-emoji-index-js": () => import("./../../../src/templates/emoji_index.js" /* webpackChunkName: "component---src-templates-emoji-index-js" */),
  "component---src-templates-emoji-page-js": () => import("./../../../src/templates/emoji_page.js" /* webpackChunkName: "component---src-templates-emoji-page-js" */),
  "component---src-templates-faq-js": () => import("./../../../src/templates/faq.js" /* webpackChunkName: "component---src-templates-faq-js" */),
  "component---src-templates-howto-index-js": () => import("./../../../src/templates/howto_index.js" /* webpackChunkName: "component---src-templates-howto-index-js" */),
  "component---src-templates-howto-page-js": () => import("./../../../src/templates/howto_page.js" /* webpackChunkName: "component---src-templates-howto-page-js" */),
  "component---src-templates-not-found-page-js": () => import("./../../../src/templates/not_found_page.js" /* webpackChunkName: "component---src-templates-not-found-page-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../../../src/templates/privacy_policy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */),
  "component---src-templates-text-style-frontpage-js": () => import("./../../../src/templates/text_style_frontpage.js" /* webpackChunkName: "component---src-templates-text-style-frontpage-js" */),
  "component---src-templates-text-style-index-js": () => import("./../../../src/templates/text_style_index.js" /* webpackChunkName: "component---src-templates-text-style-index-js" */),
  "component---src-templates-text-style-page-js": () => import("./../../../src/templates/text_style_page.js" /* webpackChunkName: "component---src-templates-text-style-page-js" */)
}

